import Vue from 'vue'
import linkify from 'vue-linkify'
import 'filepond/dist/filepond.min.css'
import Buefy from 'buefy'
import './assets/app.scss'
import VueKonva from 'vue-konva'
import LoadScript from 'vue-plugin-load-script'
import Clipboard from 'v-clipboard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import * as FullStory from '@fullstory/browser'
import VueGtm from '@gtm-support/vue2-gtm'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import { initAuth } from './auth'

import './utils/validation'
import nibble from './utils/nibble'
import App from './App.vue'
import router from './router'
import store from './store'
import maintenance from './utils/maintenance'

const FULLSTORY_ORG_ID = process.env.VUE_APP_FULLSTORY_ORG_ID
const FULLSTORY_DEV_MODE = process.env.VUE_APP_FULLSTORY_DEV_MODE || false
const GTM_ID = process.env.VUE_APP_GTM_ID
const INTERCOM_APP_ID = process.env.VUE_APP_INTERCOM_APP_ID

// FullStory
if (FULLSTORY_ORG_ID) {
  console.log(`Init FullStory. devMode: ${FULLSTORY_DEV_MODE}`)
  FullStory.init({ orgId: FULLSTORY_ORG_ID, devMode: FULLSTORY_DEV_MODE })
  window.FullStory = FullStory
}

// Google Tag Manager
if (GTM_ID) {
  Vue.use(VueGtm, {
    id: GTM_ID
  })
}

// Intercom
if (INTERCOM_APP_ID) {
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: INTERCOM_APP_ID
  };
  (function () { var w = window; var ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings) } else { var d = document; var i = function () { i.c(arguments) }; i.q = []; i.c = function (args) { i.q.push(args) }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x) }; if (document.readyState === 'complete') { l() } else if (w.attachEvent) { w.attachEvent('onload', l) } else { w.addEventListener('load', l, false) } } })()
}

Vue.use(Buefy, {
  defaultDateFormatter: (d) => { return nibble.timezoneManager.formatDate(d, 'YYYY-MM-DD') }
})
Vue.use(VueKonva)
Vue.use(LoadScript)
Vue.use(Clipboard)
Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The default CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.36.0/min/vs'
  }
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'nibble-button',
  'nibble-launch-button'
]

Vue.directive('linkified', linkify)

maintenance.checkMaintenanceStatus(store)

const init = async () => {
  await initAuth()

  new Vue({
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
}

init()
