<template>
  <div class="chat-scroll">
    <div class="chat-header">
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Fill 1</title>
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                <stop stop-color="#249AF9" offset="0%"></stop>
                <stop stop-color="#4275C4" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="UI-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-2" transform="translate(-72.000000, -20.000000)">
                <rect id="Rectangle" stroke="#E1E7EC" fill="#FFFFFF" x="-0.5" y="-0.5" width="1441" height="81"></rect>
                <path d="M86.9999537,20 C86.9999537,28.2844181 80.2843414,35.0004629 72,35.0004629 L72,35.0004629 L72,44.9999074 C72,53.2846959 78.7156123,60 86.9999537,60 L86.9999537,60 L97.0000463,60 C105.284388,60 112,53.2846959 112,44.9999074 L112,44.9999074 L112,35.0004629 C112,26.7156745 105.284388,20 97.0000463,20 L97.0000463,20 L86.9999537,20 Z M95.7112887,42.8397371 C95.7112887,39.8907509 98.1021563,37.5002315 101.050745,37.5002315 L101.050745,37.5002315 C103.999704,37.5002315 106.389831,39.8907509 106.389831,42.8397371 L106.389831,42.8397371 C106.389831,45.7883529 103.999704,48.1788723 101.050745,48.1788723 L101.050745,48.1788723 C98.1021563,48.1788723 95.7112887,45.7883529 95.7112887,42.8397371 L95.7112887,42.8397371 Z M78.2115896,42.8397371 C78.2115896,39.8907509 80.6017165,37.5002315 83.5506754,37.5002315 L83.5506754,37.5002315 C86.4996343,37.5002315 88.8897612,39.8907509 88.8897612,42.8397371 L88.8897612,42.8397371 C88.8897612,45.7883529 86.4996343,48.1788723 83.5506754,48.1788723 L83.5506754,48.1788723 C80.6017165,48.1788723 78.2115896,45.7883529 78.2115896,42.8397371 L78.2115896,42.8397371 Z" id="Fill-1" fill="url(#linearGradient-1)"></path>
            </g>
        </g>
      </svg>
    </div>
    <chat-message :session="session" :message="message" v-for="message in session.messages" :key="message.meta.messageId"></chat-message>
    <div class="chat-footer">Negotiation by <span>Nibble</span></div>
  </div>
</template>

<script>
import ChatMessage from './ChatMessage'

export default {
  name: 'ChatLog',
  components: { ChatMessage },
  props: ['session', 'admin'],
  methods: {
    messageClass (message) {
      var cls = 'message-container'
      if (message.meta != null && message.meta.understandingFailed) {
        cls = cls + ' understanding-error'
      }
      if (message.meta != null && message.meta.user_reaction) {
        cls = cls + ' with-user-reactions'
      }
      if (message.meta != null && message.meta.user_reaction) {
        cls = cls + ' with-bot-reactions'
      }
      return cls
    },
    updateScroll () {
      this.$nextTick(() => {
        setTimeout(() => {
          var container = this.$el.querySelector('.chat-scroll')
          container.scrollTop = container.scrollHeight
        }, 150)
      })
    },
    linkifyText (text) {
      // Links look like this:
      // <link text|link_url>
      const re = /<(.+)\|(.+)>/g
      const link = '<a href="$2" target="_blank">$1</a>'

      // This function call is the equivalent of text.replaceAll(re, link)
      // but certain third party js libraries override the replaceAll method.
      return re[Symbol.replace](text, link)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.chat-scroll {
  padding-top: 1em;
  max-height: calc(90vh - 320px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: url("/images/chat-background-tile-minified.png");
  background-size: 0px 0px;
  background-repeat: no-repeat;
}
.chat-scroll.capture-style {
    max-height: none !important;
    overflow: visible !important;
    overflow-y: visible !important;
    padding: 24px;
    background-size: auto;
    background-repeat: repeat;
    margin: -24px;
}
.chat-scroll .chat-header,
.chat-scroll .chat-footer {
  display: none;
}
.chat-scroll.capture-style .chat-header {
  display: block;
  margin-bottom: 25px;
  border-bottom: solid 1px #E1E7EC;
}
.chat-scroll.capture-style .chat-footer {
  display: block;
  text-align: center;
  font-size: 90%;
  color: #6E7A8B;
}
.chat-scroll.capture-style .chat-footer span {
  color: #146deb;
}
.chat-scroll.capture-style .expression {
    display: none;
}
.chat-scroll.capture-style .line .chat-message{
    background-color: #white;
}
.chat-scroll.capture-style .line.bot .chat-message{
    background-color: #f5f5f6;
}
</style>
